// @ts-check
import {bool, func, number} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import useStyles from "../surgery_assignment_canvas.styles";

/**
 * Renders the warning inside the Surgery Assignment Canvas component
 *
 * @param {object} props
 * @param {number} props.warningCount The number of surgeries with no practitioners assigned
 * @param {Function} props.toggleShowWarnings
 * @param {boolean} props.hasWarning
 * @return {React.ReactElement}
 */
export const SurgeriesWarning = ({warningCount, toggleShowWarnings, hasWarning}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    return (
        <div className={classes.warningWrapper}>
            {t("SurgeryAssignmentCanvas.editIncompleteSurgeries")}&nbsp;
            <span
                className={classes.warningSpan}
                role="button"
                tabIndex={0}
                onClick={() => toggleShowWarnings()}
                onKeyDown={(e) => e.key === "Enter" && toggleShowWarnings()}
            >
                {hasWarning
                    ? t("SurgeryAssignmentCanvas.showAllSurgeries")
                    : t("SurgeryAssignmentCanvas.showIncompleteSurgeries", {count: warningCount})}
            </span>
        </div>
    );
};

SurgeriesWarning.propTypes = {
    warningCount: number.isRequired,
    toggleShowWarnings: func.isRequired,
    hasWarning: bool.isRequired
};
