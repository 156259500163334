import {makeStyles} from "tss-react/mui";
/**
 * @todo #14788: theme.typography throws error, to be fixed
 * @type {object}
 */
export default makeStyles()((theme) => ({
    dialogWrapper: {
        maxWidth: "780px"
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        textTransform: "unset",
        padding: "2.5rem",
        ...theme.typography.h6
    },
    icon: {
        color: theme.palette.grey[700],
        cursor: "pointer",
        fontSize: "1.4rem",
        marginTop: theme.spacing("xxs")
    },
    practitionerDialogSize: {height: "500px", width: "680px"},
    tableWrapper: {
        "overflow": "auto",
        "& .MuiTableCell-stickyHeader": {
            backgroundColor: theme.palette.background.paper
        },
        "& .MuiTableSortLabel-root": {
            width: "100%"
        }
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "space-between !important"
    },
    button: {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing("xs")} 1rem`,
        letterSpacing: "0.3px"
    },
    buttonOpView: {
        "fontSize": "14px",
        "color": theme.palette.primary.main,
        "fontWeight": theme.typography.fontWeightRegular,
        "textTransform": "unset",
        "padding": "2px",
        "boxShadow": "unset",
        "cursor": "pointer",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    buttonShowHideWrapper: {
        borderBottom: "1px solid #e0e0e0"
    },
    buttonShowHide: {
        display: "flex",
        height: "50px",
        fleyGrow: 1,
        alignItems: "center",
        paddingLeft: "8px"
    }
}));
