import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import React, {Fragment, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {filterAllowedItems} from "../../utils/menu";
import {useSecurity} from "../../utils/security";
import useStyles from "./sitemap_links.styles";

const SitemapLinks = ({menu}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const {permissions} = useSecurity();

    const sitemap = useMemo(() => filterAllowedItems(menu, permissions), [menu, permissions]);

    return (
        <ul className={classes.list}>
            {sitemap
                .filter((item) => item.pages)
                .map((item) => (
                    <Fragment key={item.name}>
                        <li className={classes.mainItem}>
                            <Link className={classes.link} color="primary" href={item.pages[0].route} underline="none">
                                {t(item.translationKey)}
                            </Link>
                        </li>
                        {item.pages.map((page) => (
                            <li className={classes.item} key={page.name}>
                                <Link className={classes.link} color="primary" href={page.route} underline="none">
                                    {t(page.translationKey)}
                                </Link>
                            </li>
                        ))}
                    </Fragment>
                ))}
        </ul>
    );
};

SitemapLinks.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            translationKey: PropTypes.string.isRequired,
            pages: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    route: PropTypes.string.isRequired,
                    translationKey: PropTypes.string.isRequired
                })
            )
        })
    )
};

export default SitemapLinks;
