// @ts-check
import {Close} from "@mui/icons-material";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {func, object} from "prop-types";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import usePrevious from "../../hooks/usePrevious";
import {modifySurgeryAssignmentList} from "../../pages/surgery_assignment/surgery_assignment_actions";
import {
    selectIsSurgeonSelectorOpen,
    selectSurgeonIdOptionsForDisciplineList,
    selectSurgeryAssignmentErrors,
    selectSurgeryAssignmentModifyStatus
} from "../../pages/surgery_assignment/surgery_assignment_selectors";
import STATUS from "../../redux/utils/status";
import {selectSurgeryAssignmentConfig} from "../fe_settings/fe_settings_selectors";
import {selectAllFullNamesObject} from "../private_data/private_data_selectors";
import SurgeryAssignmentManageTable from "./components/surgery_assignment_manage_table";
import useStyles from "./surgery_assignment_manage_dialog.styles";
import {
    formatAddingSurgeonList,
    formatProcedureToSurgeonCategory,
    formatSurgeonCategoryToProcedure,
    mergeSurgeonData,
    toggleSurgeonCategory
} from "./utils/helpers";

export const RHF_NAMES = {
    addingSurgeons: "addingSurgeons",
    modifiedSurgeons: "modifiedSurgeons" // includes add/change/remove
};

/**
 * Render Surgery Assignment Manage Dialog
 * @param {Object} props
 * @param {ProcedureInfo} props.procedure
 * @param {Function} props.onClose
 * @return {React.ReactElement}
 */
const SurgeryAssignmentManageDialog = ({procedure, onClose}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // Redux store
    const {categories, groupProcedureBy} = useSelector(selectSurgeryAssignmentConfig);
    /** @type {IdToNameCollection|{}} */
    const allNamesObject = useSelector(selectAllFullNamesObject({type: "practitioner"}));
    /** @type string[] */
    const surgeonIds = useSelector(selectSurgeonIdOptionsForDisciplineList({hcServiceId: procedure.healthcareServiceId}));
    const patchErrors = useSelector(selectSurgeryAssignmentErrors);
    const patchStatus = useSelector(selectSurgeryAssignmentModifyStatus);
    const previousStatus = usePrevious(patchStatus);
    const isSurgeonSelectorOpen = useSelector(selectIsSurgeonSelectorOpen);

    useEffect(() => {
        if (previousStatus && previousStatus === STATUS.PENDING && patchStatus === STATUS.RESOLVED && patchErrors.length === 0) {
            onClose();
        }
    }, [previousStatus, patchStatus, patchErrors]);

    // Prepare react-hook-forms
    const {control, watch, setValue} = useForm({
        defaultValues: {
            [RHF_NAMES.addingSurgeons]: formatAddingSurgeonList(procedure, allNamesObject, surgeonIds),
            [RHF_NAMES.modifiedSurgeons]: formatProcedureToSurgeonCategory(procedure, allNamesObject)
        }
    });

    /** @type {Array<SurgeonCategory>} */
    const watchAddingSurgeons = watch(RHF_NAMES.addingSurgeons);
    /** @type {Array<SurgeonCategory>} */
    const watchModifiedSurgeons = watch(RHF_NAMES.modifiedSurgeons);

    /**
     * handler to saving the assignedPractitioners
     */
    const handleSave = () => {
        dispatch(modifySurgeryAssignmentList(formatSurgeonCategoryToProcedure(procedure.procedureCode, watchModifiedSurgeons, categories)));
    };

    /**
     * handler to adding surgeons from the ControlledSurgeryAssignmentSelector into SurgeryAssignmentManageTable
     *   - add surgeons who have at least categories checked into modifiedSurgeons
     *   - remove surgeons from the addingSurgeons
     */
    const handleAddSurgeons = () => {
        const {mergedData, addedIds} = mergeSurgeonData(watchModifiedSurgeons, watchAddingSurgeons);
        setValue(RHF_NAMES.modifiedSurgeons, mergedData);
        setValue(
            RHF_NAMES.addingSurgeons,
            watchAddingSurgeons.filter((addingSurgeon) => !addedIds.includes(addingSurgeon.id))
        );
    };

    /**
     * handler to toggling the checkbox in the SurgeryAssignmentManageTable
     * @param {String} id
     * @param {String} category
     */
    const handleToggleCategory = (id, category) => {
        setValue(RHF_NAMES.modifiedSurgeons, toggleSurgeonCategory(watchModifiedSurgeons, {id, category}));
    };

    /**
     * handler to removing a surgeon from the SurgeryAssignmentManageTable
     *   - remove a surgeon from the modifiedSurgeons
     *   - add a surgeon into addingSurgeons so that the surgeon can be added from the selector
     * @param {String} id
     */
    const handleRemoveSurgeon = (id) => {
        const removedSurgeon = watchModifiedSurgeons.find((surgeonCategory) => surgeonCategory.id === id);
        setValue(
            RHF_NAMES.modifiedSurgeons,
            watchModifiedSurgeons.filter((surgeonCategory) => surgeonCategory.id !== id)
        );
        // clear the categories
        removedSurgeon.categories = [];
        setValue(RHF_NAMES.addingSurgeons, [...watchAddingSurgeons, removedSurgeon]);
    };

    /**
     * handler to resetting the check in the ControlledSurgeryAssignmentSelector
     */
    const handleResetAddSurgeons = () => {
        setValue(
            RHF_NAMES.addingSurgeons,
            watchAddingSurgeons.map((addingSurgeon) => ({...addingSurgeon, categories: []}))
        );
    };

    const content = procedure && (
        <>
            <span className={classes.name}>
                {procedure.procedureName}
                {groupProcedureBy.includes("healthcareServiceId") &&
                    t("SurgeryAssignmentManageDialog.healthcareServiceName", {
                        hcsName: t(`HealthcareService.${procedure.healthcareServiceId}`, procedure.healthcareServiceId)
                    })}
            </span>
            <div className={classes.errorArea}>{patchErrors.length > 0 && t("SurgeryAssignmentManageDialog.errorText")}</div>
            <SurgeryAssignmentManageTable
                categories={categories}
                control={control}
                errors={patchErrors}
                surgeonsWithCategories={watchModifiedSurgeons}
                onAddSurgeons={handleAddSurgeons}
                onRemoveSurgeon={handleRemoveSurgeon}
                onResetAddSurgeons={handleResetAddSurgeons}
                onToggleCategory={handleToggleCategory}
            />
        </>
    );
    return (
        <div
            className={cx(classes.dialogWrapper, {
                [classes.isSurgeonSelectorOpen]: isSurgeonSelectorOpen
            })}
        >
            <DialogTitle className={classes.title}>
                {t("SurgeryAssignmentManageDialog.title")}
                <Close className={classes.icon} onClick={() => onClose()} />
            </DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions classes={{root: classes.buttonWrapper}}>
                <Button
                    className={classes.button}
                    color="primary"
                    data-testid="surgery-assignment-manage-dialog-cancel"
                    // @ts-ignore
                    disabled={isSurgeonSelectorOpen}
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    {t("SurgeryAssignmentManageDialog.cancel")}
                </Button>
                <Button
                    className={classes.button}
                    color="primary"
                    data-testid="surgery-assignment-manage-dialog-save"
                    // @ts-ignore
                    disabled={isSurgeonSelectorOpen}
                    variant="contained"
                    onClick={handleSave}
                >
                    {t("SurgeryAssignmentManageDialog.save")}
                </Button>
            </DialogActions>
        </div>
    );
};

SurgeryAssignmentManageDialog.propTypes = {
    procedure: object,
    onClose: func.isRequired
};

export default SurgeryAssignmentManageDialog;
