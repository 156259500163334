// @ts-check
import {Checkbox, TableCell, TableRow} from "@mui/material";
import {array, arrayOf, func, object, string} from "prop-types";
import React from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectIsSurgeonSelectorOpen} from "../../../pages/surgery_assignment/surgery_assignment_selectors";
import DataTable from "../../shared/data_table/data_table";
import DeleteConfirmation from "../../shared/delete_confirmation/delete_confirmation";
import {RHF_NAMES} from "../surgery_assignment_manage_dialog";
import useStyles from "../surgery_assignment_manage_dialog.styles";
import {ControlledSurgeryAssignmentSelector} from "./controlled_surgery_assignment_selector";

export const TABLE_WIDTH = 680;
export const TABLE_HEIGHT = 500; // 740 - 240 (max height of the dialog: 800px - the height of rest items: 240px)
export const COLUMN_WIDTH = {name: 35, categories: 45, actions: 20};

/**
 * Render SurgeryAssignmentManageTable component shown in the SurgeryAssignmentManageDialog
 * @param {Object} props
 * @param {Array<SurgeonCategory>} props.surgeonsWithCategories
 * @param {Function} props.onToggleCategory
 * @param {Function} props.onRemoveSurgeon
 * @param {Array<string>} props.categories
 * @param {Function} props.onAddSurgeons
 * @param {Object} props.control
 * @param {Array<SurgeryAssignmentPatchError>} [props.errors]
 * @param {Function} props.onResetAddSurgeons
 * @return {React.ReactElement}
 */
const SurgeryAssignmentManageTable = ({
    surgeonsWithCategories,
    onToggleCategory,
    onRemoveSurgeon,
    categories,
    onAddSurgeons,
    control,
    errors,
    onResetAddSurgeons
}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const isSurgeonSelectorOpen = useSelector(selectIsSurgeonSelectorOpen);

    // Prepare labels
    /** @type Array<DataTableLabel> */
    const labels = [
        {
            id: "name",
            label: t("SurgeryAssignmentManageDialog.surgeonName"),
            width: `${COLUMN_WIDTH.name}%`,
            setTitle: true
        },
        {
            id: "categories",
            label: <span className={classes.categoriesLabel}>{t("SurgeryAssignmentManageDialog.categories")}</span>,
            width: `${COLUMN_WIDTH.categories}%`,
            setTitle: false
        },
        {
            id: "action",
            label: t("SurgeryAssignmentManageDialog.action"),
            width: `${COLUMN_WIDTH.actions}%`,
            setTitle: false
        }
    ];

    const formatTableData = (data) => {
        return data.map((row) => {
            const {id, name, categories: surgeonCategories} = row;
            const hasError = !!errors.find((error) => error.practitionerId === id);
            const categoryCheckboxes = (
                <span className={classes.checkboxesWrapper}>
                    {categories.map((category) => {
                        return (
                            <Checkbox
                                checked={surgeonCategories.includes(category)}
                                className={cx(classes.checkbox, {[classes.checkboxError]: hasError})}
                                // @ts-ignore
                                inputProps={{"data-testid": `${id}-${category}`}}
                                key={`${id}-${category}`}
                                size={"small"}
                                onClick={() => onToggleCategory(id, category)}
                            />
                        );
                    })}
                </span>
            );
            return {
                id,
                key: id,
                name,
                display: {
                    name,
                    categories: categoryCheckboxes,
                    action: (
                        <DeleteConfirmation
                            trigger={{
                                color: "primary",
                                label: t("SurgeryAssignmentManageDialog.remove")
                            }}
                            onConfirm={() => onRemoveSurgeon(row.id)}
                        >
                            {t("SurgeryAssignmentManageDialog.removeConfirmation", {name})}
                        </DeleteConfirmation>
                    )
                },
                tooltip: {
                    name
                },
                isEmergency: hasError
            };
        });
    };

    const extraHeader = (
        <TableRow>
            <TableCell key={"surgeons-assignment-selector"}>
                <ControlledSurgeryAssignmentSelector
                    categories={categories}
                    control={control}
                    errors={errors[RHF_NAMES.addingSurgeons]}
                    name={RHF_NAMES.addingSurgeons}
                    onAdd={onAddSurgeons}
                    onReset={onResetAddSurgeons}
                />
            </TableCell>
            <TableCell key={"surgeons-assignment-checkbox-labels"}>
                <span className={classes.checkboxesWrapper}>
                    {categories.map((category) => (
                        <span className={classes.categoryLabel} key={"label-" + category}>
                            {t(`SurgeryAssignmentManageDialog.${category}`)}
                        </span>
                    ))}
                </span>
            </TableCell>
            <TableCell />
        </TableRow>
    );
    return (
        <div
            className={cx(classes.tableWrapper, {
                [classes.tableWrapperSelectorOpen]: isSurgeonSelectorOpen
            })}
            data-testid="surgery-assignments-table"
            style={{height: TABLE_HEIGHT + "px"}}
        >
            <Controller
                control={control}
                name={RHF_NAMES.modifiedSurgeons}
                render={({field: {value, onChange}}) => (
                    <DataTable
                        data={formatTableData(surgeonsWithCategories)}
                        disableSortColumns={["categories", "action"]}
                        extraHeader={extraHeader}
                        isPaginationVisible={false}
                        labels={labels}
                        tableTopBottomPadding={0}
                        tableWidthProps={TABLE_WIDTH}
                    />
                )}
            />
        </div>
    );
};

SurgeryAssignmentManageTable.propTypes = {
    surgeonsWithCategories: array.isRequired,
    onToggleCategory: func.isRequired,
    onRemoveSurgeon: func.isRequired,
    categories: arrayOf(string).isRequired,
    onAddSurgeons: func.isRequired,
    control: object.isRequired,
    errors: array,
    onResetAddSurgeons: func.isRequired
};

export default SurgeryAssignmentManageTable;
