// @ts-check
import uniqBy from "lodash/uniqBy";
import {useEffect, useState} from "react";

/**
 * @typedef RepeatedExperiencesDic
 * @type {Object.<string, {typeList: string[], count: number}>}
 */

/**
 * Builds a dictionary to group the experiences by procedure code. This way we can have
 * the `count` (the number of times performed) and the `type` of practitioner, for each procedure.
 *
 * @param {Experience[]} experience
 * @return {RepeatedExperiencesDic}
 */
const buildRepeatedExperiencesDict = (experience) =>
    experience.reduce((acc, {count, procedureCode, type}) => {
        const accKey = acc[procedureCode];
        if (accKey === undefined) {
            acc[procedureCode] = {typeList: [type], count};
        } else {
            accKey.typeList.push(type);
            accKey.count += count;
        }
        return acc;
    }, {});

/**
 * Format the list of experiences so there is only one Experience for each procedure
 * this is necessary to have one row per surgery in the SurgeryAssignmentPractitionerDialog component
 *
 * @param {Experience[]} experience
 * @param {RepeatedExperiencesDic} repeatedExperiencesDictionary
 * @return {Array<ExperienceExtended>}
 */
const formatExperiencesFromDict = (experience, repeatedExperiencesDictionary) =>
    experience.map((exp) => {
        const key = repeatedExperiencesDictionary[exp?.procedureCode];
        if (key !== undefined) return {...exp, count: key.count, typeList: key.typeList};
        return undefined;
    });

/**
 * A custom hook to sort the practitioner experiences so that the clicked
 * surgery appears first in the array of experiences. In addition, it groups the surgeries assigned to a practitioner.
 * For instance if Dr. Doolittle is assigned as "O" and "M" for surgery "XLM", 21 times as "0" and 13 times as "M".
 * Then we would have in the row for that surgery ==>  "XLM | O, M | 34 "
 *
 * @param {object} params
 * @param {Array<PractitionerExperience>} params.practitionerExperience
 * @param {PractitionerDialogInfo} params.practitioner
 * @return {{practitionerExperienceSorted: PractitionerExperienceExtended}}}
 */
export const useSortPractitionerExperience = ({practitionerExperience, practitioner}) => {
    /** @type [PractitionerExperienceExtended, Function] State */
    const [practitionerExperienceSorted, setPractitionerExperienceSorted] = useState({practitionerId: null, experience: []});

    useEffect(() => {
        if (practitionerExperience.length > 0) {
            const {experience} = practitionerExperience[0];
            const repeatedExperiencesDictionary = buildRepeatedExperiencesDict(experience);

            const experiencesFormatted = formatExperiencesFromDict(experience, repeatedExperiencesDictionary);
            const experiencesFormattedNoDuplicates = uniqBy(experiencesFormatted, "procedureCode");

            const experienceListWithoutClickedProcedure = experiencesFormattedNoDuplicates.filter(
                ({procedureCode}) => procedureCode !== practitioner?.procedureCode
            );

            const clickedProcedure = experiencesFormattedNoDuplicates.find(
                ({procedureCode}) => procedureCode === practitioner?.procedureCode
            );

            const practitionerExperienceSortedNew = {
                practitionerId: practitioner.practitionerId,
                experience: [clickedProcedure, ...experienceListWithoutClickedProcedure]
            };

            setPractitionerExperienceSorted(practitionerExperienceSortedNew);
        }
    }, [practitionerExperience[0]?.practitionerId, practitionerExperience[0]?.experience.length]);

    return {practitionerExperienceSorted};
};
