// @ts-check
import {Close} from "@mui/icons-material";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {func, shape, string} from "prop-types";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {loadSurgeonExperienceAction} from "../../pages/surgery_assignment/surgery_assignment_actions";
import {selectSurgeonExperience, selectSurgeonExperienceStatus} from "../../pages/surgery_assignment/surgery_assignment_selectors";
import STATUS from "../../redux/utils/status";
import {selectFullName} from "../private_data/private_data_selectors";
import SurgeryAssignmentPractitionerTable from "./components/surgery_assignment_practitioner_table";
import {useSortPractitionerExperience} from "./hooks/useSortPractitionerExperiences";
import useStyles from "./surgery_assignment_practitioner_dialog.styles";

/**
 * Render SurgeryAssignmentPractitionerDialog
 *
 * @param {Object} props
 * @param {PractitionerDialogInfo} props.practitioner
 * @param {Function} props.onClose
 * @param {Function} props.onOpenManageDialog
 * @return {React.ReactElement}
 */
const SurgeryAssignmentPractitionerDialog = ({practitioner, onClose, onOpenManageDialog}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // Redux store
    const practitionerExperience = useSelector(selectSurgeonExperience({practitionerId: practitioner?.practitionerId}));
    const status = useSelector(selectSurgeonExperienceStatus);
    const fullName = useSelector(selectFullName({id: practitioner?.practitionerId, type: "practitioner"}));

    const {practitionerExperienceSorted} = useSortPractitionerExperience({practitionerExperience, practitioner});

    useEffect(() => {
        if (practitioner?.practitionerId) {
            dispatch(loadSurgeonExperienceAction([practitioner.practitionerId]));
        }
    }, [practitioner]);

    if (status === STATUS.IDLE) return null;
    return (
        <div className={classes.dialogWrapper}>
            <DialogTitle className={classes.title}>
                {fullName}
                <Close className={classes.icon} onClick={() => onClose()} />
            </DialogTitle>
            {Boolean(practitionerExperienceSorted.practitionerId) && (
                <DialogContent>
                    <SurgeryAssignmentPractitionerTable
                        practitionerExperience={practitionerExperienceSorted}
                        onOpenManageDialog={onOpenManageDialog}
                    />
                </DialogContent>
            )}
            <DialogActions classes={{root: classes.buttonWrapper}}>
                <Button
                    className={cx(classes.button, classes.buttonWhite)}
                    color="primary"
                    data-testid="surgery-assignment-manage-dialog-cancel"
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    {t("SurgeryAssignmentManageDialog.cancel")}
                </Button>
            </DialogActions>
        </div>
    );
};

SurgeryAssignmentPractitionerDialog.propTypes = {
    practitioner: shape({
        practitionerId: string,
        procedureCode: string
    }),
    onClose: func.isRequired,
    onOpenManageDialog: func.isRequired
};

export default SurgeryAssignmentPractitionerDialog;
