// @ts-check
import {func, object, string} from "prop-types";
import React from "react";

import useStyles from "../surgery_assignment_canvas.styles";

/**
 * The span that wraps each name to make it clickable
 *
 * @param {object} props The props
 * @param {NamesColumnItem} props.column The NamesColumnItem
 * @param {Function} props.handler The handler to open the dialog for each practitioner
 * @param {string} props.procedureCode The unique code for each procedure
 * @return {React.ReactElement}
 */
export const PractitionerName = ({column, handler, procedureCode}) => {
    const {classes} = useStyles();
    const {id: practitionerId, name} = column;
    return (
        <span
            className={classes.practitionerNameSpan}
            key={practitionerId}
            role="button"
            tabIndex={0}
            onClick={() => handler({practitionerId, procedureCode})}
            onKeyDown={(e) => e.key === "Enter" && handler({practitionerId, procedureCode})}
        >
            {name}
        </span>
    );
};

PractitionerName.propTypes = {
    handler: func.isRequired,
    procedureCode: string.isRequired,
    column: object.isRequired
};
