import {makeStyles} from "tss-react/mui";
/**
 * @todo #14788: theme.typography throws error, to be fixed
 * @type {object}
 */
export default makeStyles()((theme) => ({
    content: {
        padding: "30px"
    },
    input: {
        width: "10rem"
    },
    filtersWrapper: {
        "padding": `1.6rem ${theme.spacing("m")} ${theme.spacing("s")} ${theme.spacing("m")}`,
        "display": "flex",
        "& .MuiFormHelperText-root.Mui-error": {
            fontSize: "0.688rem"
        }
    },
    tableWrapper: {
        padding: `0 ${theme.spacing("m")}`
    },
    resetButtonWrapper: {
        display: "flex",
        alignItems: "flex-end",

        marginLeft: "24px"
    },
    resetButton: {
        "fontSize": "0.7rem",
        "color": theme.palette.primary.main,
        "cursor": "pointer",
        "textTransform": "capitalize",
        "marginBottom": "20px",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline"
        }
    },
    buttonEdit: {
        fontSize: "14px",
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "capitalize",
        padding: "2px"
    },
    checkbox: {
        color: theme.palette.primary.main,
        padding: "2px"
    },
    title: {
        ...theme.custom.canvasSubTitle
    },
    autocomplete: {width: "240px", marginRight: "20px !important", marginBottom: "20px !important"},
    select: {width: "240px", marginRight: "20px !important", marginBottom: "20px !important"},
    warningWrapper: {
        backgroundColor: theme.custom.emergency.light,
        padding: "10px 14px",
        margin: "21px 30px 0px 30px",
        fontSize: "14px",
        height: "39px",
        color: theme.palette.error.main,
        fontWeight: 500
    },
    warningSpan: {textDecoration: "underline", cursor: "pointer"},
    practitionerNameSpan: {
        "cursor": "pointer",
        "color": theme.palette.primary.main,
        "marginRight": "6px",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline"
        },
        "&:after": {
            display: "inline",
            content: '", "'
        },
        "&:last-child": {
            "&:after": {
                display: "inline",
                content: '""'
            }
        }
    }
}));
