// @ts-check
import {t} from "i18next";
import find from "lodash/find";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import React from "react";

import {sortArrayOfObjectsByKey} from "../../utils/sort_array_of_objects_by_key";
import DoneIcon from "../shared/icons/done_icon";
import {PractitionerName} from "./components/practitioner_name";

export const TABLE_WIDTH = Object.freeze({surgery: 10, group: 10, check: 10, name: 60, edit: 10});

/**
 * A getter for the basic columns labels
 *
 * @return {[DataTableLabel]} The basic label(s)
 */
export const getBasicLabels = () => [
    {id: "surgery", label: t("SurgeryAssignmentCanvas.surgery"), width: `${TABLE_WIDTH.surgery}%`, setTitle: true}
];

/**
 * A getter for the edit label
 *
 * @return {[DataTableLabel]} The edit label
 */
export const getEditLabel = () => [
    {id: "edit", label: t("SurgeryAssignmentCanvas.actions"), width: `${TABLE_WIDTH.edit}%`, setTitle: true}
];

/**
 * Build an array of label-types depending on the specific configuration for each hospital. The
 * labels configuration is located under feSettings.surgeryAssignment.columns in our store.
 *
 * @param {object} data The wrapper object
 * @param {Array<SurgeryAssignmentColumn>} data.columns The columns for the specific hospital
 * @param {("check"|"names")} data.type The type of data the label will have
 * @return {Array<DataTableLabel>} The extended list of labels
 */
export const buildLabelTypes = ({columns, type}) =>
    columns.map(({name}) => {
        const colNum = Object.keys(columns).length;
        return {
            id: `${name}-${type}`,
            label: t(`SurgeryAssignment.${name}-${type}`),
            width: type === "check" ? `${TABLE_WIDTH.check / colNum}%` : `${TABLE_WIDTH.name / colNum}%`,
            setTitle: type === "check" ? false : true,
            allowWrap: true
        };
    });

/**
 * Build a list of group-labels depending on the specific configuration for each hospital. The
 * needed configuration is under feSettings.surgeryAssignment.groupProcedureBy
 *
 * @param {Array<string>|undefined} groupProcedureBy The list of procedures to be grouped
 * @return {Array<DataTableLabel>} The extended list of labels
 */
export const buildLabelGroups = (groupProcedureBy) =>
    groupProcedureBy?.map((procedure) => ({
        id: `group-${procedure}`,
        label: t(`SurgeryAssignmentCanvas.${procedure}`),
        width: TABLE_WIDTH.group / Object.keys(groupProcedureBy).length + "%",
        setTitle: true
    })) || [];

/**
 * Convert a list of ids into real names using information from the privateData slice
 *
 * @param {Array<string>} ids The list of ids to transform into real names
 * @param {IdToNameCollection} allNamesObject The object containing the ids mapped to real names
 * @return {Array<string>} The list of real names
 */
export const getNames = (ids, allNamesObject) => ids.map((id) => allNamesObject[id] || t("App.unknown"));

/**
 * Convert a list of ids into a list of NamesColumnItem
 *
 * @param {Array<string>} ids The list of ids
 * @param {IdToNameCollection} allNamesObject The object containing the ids mapped to real names
 * @return {Array<NamesColumnItem>}
 */
export const mapIdsToNameIdObjectList = (ids, allNamesObject) => ids.map((id) => ({id, name: allNamesObject[id] || t("App.unknown")}));

/**
 * For each practitioner type, convert the practitioner list of ids to a list of NamesColumnItem
 *
 * @param {Array<AssignedPractitioners>} assignedPractitioners The assigned practitioners for the current surgery
 * @param {IdToNameCollection} allNamesObject An object containing the ids mapped to real names
 * @return {Object.<string, NamesColumnItem[]>}
 */
export const getPractitionersNamesAndIds = (assignedPractitioners, allNamesObject) =>
    assignedPractitioners.reduce((acc, {type, practitioners}) => {
        acc[type] = mapIdsToNameIdObjectList(practitioners, allNamesObject);
        return acc;
    }, {});

/**
 * Checks if a list of assignedPractitioners has at least one practitioner
 *
 * @param {Array<string>} content The list of practitioner types
 * @param {Array<AssignedPractitioners>} assignedPractitioners The assigned practitioners to check
 * @return {boolean} Whether there is at least one practitioner in at least one of the AssignedPractitioners collection
 */
export const hasPractitioner = (content, assignedPractitioners) => {
    let hasAtLeastOnePractitioner = false;
    content.forEach((practitionerType) => {
        assignedPractitioners.forEach(({type, practitioners}) => {
            if (type === practitionerType && practitioners.length > 0) hasAtLeastOnePractitioner = true;
        });
    });
    return hasAtLeastOnePractitioner;
};

/**
 * Analyze the columns for the specific hospital and adds a <DoneIcon /> or a "-"
 * depending on whether there is at least one practitioner for that column or not.
 *
 * @param {Array<SurgeryAssignmentColumn>} columns The columns for the specific hospital
 * @param {Array<AssignedPractitioners>} assignedPractitioners The row data
 * @return {Object.<[string], React.ReactElement>}
 */
export const addChecksToRow = (columns, assignedPractitioners) =>
    columns.reduce((acc, {name, content}) => {
        acc[`${name}-check`] = hasPractitioner(content, assignedPractitioners) ? <DoneIcon /> : <div>-</div>;
        return acc;
    }, {});

/**
 *
 * @param {Array<string>} groupProcedureBy The list of procedure to be grouped
 * @param {ProcedureInfo} procedureInfo The procedure information for the current row
 * @return {Object.<string, string[]>}
 */
export const addGroupsToRow = (groupProcedureBy, procedureInfo) =>
    groupProcedureBy.reduce((acc, procedure) => {
        const procedureValue = procedureInfo[procedure];
        if (procedure === "healthcareServiceId") {
            acc[`group-${procedure}`] = t(`HealthcareServiceAbbreviation.${procedureValue}`, procedureValue);
        } else {
            acc[`group-${procedure}`] = procedureValue;
        }
        return acc;
    }, {});

/**
 * Analyze the columns for the specific hospital and builds a list of NamesColumnItem
 *
 * @param {object} data The wrapper object
 * @param {Array<SurgeryAssignmentColumn>} data.columns The current columns for the specific hospital
 * @param {Object.<string, NamesColumnItem[]>} data.practitionerNameAndIdList The assignedPractitioners collection with ids and realNames
 * @param {Array<NameDecorator>} data.nameDecorator
 * @return {Object.<[string], NamesColumnItem[]>} An object with the data of all the columns that have names
 */
export const formatNameColumnsStructure = ({columns, practitionerNameAndIdList, nameDecorator}) =>
    columns.reduce((acc, {content, name}) => {
        acc[`${name}-names`] = formatNamesRow(content || [], practitionerNameAndIdList, nameDecorator);
        return acc;
    }, {});

/**
 * We need to have handlers for each practitioner in order to be able
 * to click on each individual name. Although using "reduce" can be frown upon,
 * in this case I believe it doesn't make the function illegible.
 *
 * @param {Object.<string, NamesColumnItem[]>} namesColumnList
 * @param {Function} onOpenPractitionerDialog A handler to open an individual dialog for each name
 * @param {string} procedureCode The code of the procedure
 * @return {{namesColumnsSpanList: Object.<string, (React.ReactElement[]|"-")>}}
 */
export const mapNamesToSpans = (namesColumnList, onOpenPractitionerDialog, procedureCode) => {
    const namesColumnsSpanList = Object.keys(namesColumnList).reduce((acc, nameColum) => {
        const practitionerList = namesColumnList[nameColum];

        const isValueEmpty = practitionerList.length === 0;
        const renderPractitionerNameSpan = (column) => (
            <PractitionerName column={column} handler={onOpenPractitionerDialog} key={column.id} procedureCode={procedureCode} />
        );

        acc[nameColum] = !isValueEmpty ? practitionerList.map(renderPractitionerNameSpan) : "-";
        return acc;
    }, {});

    return {namesColumnsSpanList};
};

/**
 * Add a suffix to the practitioners' names depending on its type. The list of practitioners' types
 * to be decorated are located in our store, under feSettings.surgeryAssignment.nameDecorator
 *
 * @param {Array<{id: string, name: string}>} rowContent A list of practitioners' names or ids
 * @param {Array<NameDecorator>} nameDecorator @see NameDecorator typedef
 * @param {Object.<string, NamesColumnItem[]>} practitionerNames A collection containing the assignedPractitioners from the api call, but with the ids mapped to real names
 * @return {Array<NamesColumnItem>} The list of names from rowContent, with the suffix decorator applied when required
 */
const decorateNames = (rowContent, nameDecorator, practitionerNames) => {
    const rowContentDecorated = [];

    rowContent.forEach((practitioner) => {
        const {id, name} = practitioner;

        nameDecorator.forEach(({type, suffix}) => {
            const listToDecorate = practitionerNames[type];
            const isPractitionerThere = find(listToDecorate, practitioner) !== undefined;

            if (listToDecorate !== undefined && isPractitionerThere) {
                rowContentDecorated.push({id, name: `${name} ${suffix}`});
            } else {
                rowContentDecorated.push(practitioner);
            }
        });
    });
    return rowContentDecorated;
};

/**
 * Build the list of NamesColumnItem to be shown in each column.
 *
 * @param {Array<string>} content The list of practitioner types
 * @param {Object.<string, NamesColumnItem[]>} practitionerNames A collection containing the assignedPractitioners from the api call
 * @param {Array<NameDecorator>} nameDecorator @see NameDecorator typedef
 * @return {Array<{id: string, name:string}>}
 */
const formatNamesRow = (content, practitionerNames, nameDecorator) => {
    const rowContentArray = [];

    content.forEach((practitionerType) => {
        const idNameList = practitionerNames[practitionerType];
        if (idNameList !== undefined) rowContentArray.push(idNameList);
    });
    const rowContentFlattened = rowContentArray.flat();

    const rowContentNoDuplicates = uniqBy(rowContentFlattened, "id");

    if (isEmpty(nameDecorator)) {
        return rowContentNoDuplicates.sort((a, b) => sortArrayOfObjectsByKey(a, b, "name"));
    }

    const rowContentDecorated = decorateNames(rowContentNoDuplicates, nameDecorator, practitionerNames);

    const rowContent = rowContentDecorated.sort((a, b) => sortArrayOfObjectsByKey(a, b, "name"));

    return rowContent;
};

/**
 * Checks if the list of practitioners is empty for every practitioner type.
 *
 * @param {Array<AssignedPractitioners>} assignedPractitioners
 * @return {boolean} Whether there are practitioners assigned to the row
 */
export const hasNoPractitioners = (assignedPractitioners) => !assignedPractitioners.some(({practitioners}) => practitioners.length > 0);

/**
 * Count how many assignments without at least one practitioner assigned are.
 *
 * @param {ProcedureInfo[]} data
 * @return {number} The number of assignments with no practitioners
 */
export const countWarnings = (data) => {
    let warningsCount = 0;
    data.forEach(({assignedPractitioners}) => {
        if (hasNoPractitioners(assignedPractitioners)) {
            warningsCount++;
        }
    });
    return warningsCount;
};

/**
 *
 * @param {string} procedureCategory The category a surgery belongs to, in case it is used.
 * @param {Array<string>} categories The selected categories
 * @return {boolean}
 */
export const filterCategories = (procedureCategory, categories) => (categories.length > 0 ? categories.includes(procedureCategory) : true);

/**
 * @template {string} T
 * @param {string} procedureName The surgery name
 * @param {Array<{label: T, value: T}>} surgeryNames The selected surgery names
 * @return {boolean}
 */
export const filterProcedureNames = (procedureName, surgeryNames) =>
    surgeryNames?.length > 0 ? surgeryNames?.some(({value}) => value === procedureName) : true;

/**
 *
 * @param {string} healthcareServiceId The healthcareServiceId
 * @param {Array<string>} services The selected healthcareServiceIds
 * @return {boolean}
 */
export const filterHealthCareServiceId = (healthcareServiceId, services) =>
    services?.length > 0 ? services.includes(healthcareServiceId) : true;

/**
 *
 * @param {Array<string>} operatorNameList The list of operator names
 * @param {Array<{label: string, value: string}>} selectedOperatorNames The selected operator names
 * @return {boolean}
 */
export const filterOperatorNames = (operatorNameList, selectedOperatorNames) =>
    selectedOperatorNames?.length > 0 ? selectedOperatorNames.some(({value}) => operatorNameList.includes(value)) : true;

/**
 *
 * @param {Array<string>} practitionerTypeList The list of practitioner types for each surger
 * @param {Array<string>} selectedPractitionerTypes The selected practitioner types
 * @return {boolean}
 */
export const filterPractitionerTypes = (practitionerTypeList, selectedPractitionerTypes) =>
    selectedPractitionerTypes.length > 0 ? intersection(practitionerTypeList, selectedPractitionerTypes).length > 0 : true;

/**
 * @param {object} params
 * @param {ProcedureInfo[]} params.surgeryAssignmentList
 * @param {IdToNameCollection} params.allNamesObject
 * @param {Array<string>} params.selectedCategories
 * @param {Array<{label: string, value: string}>} params.selectedProcedures
 * @param {Array<string>} params.selectedHealthcareServices
 * @param {Array<{label: string, value: string}>} params.selectedOperatorNames
 * @param {Array<string>} params.selectedPractitionerTypes
 * @param {boolean} params.hasJustIncompleteSurgeries
 * @return {ProcedureInfo[]}
 */
export const filterSurgeries = ({
    surgeryAssignmentList,
    selectedCategories,
    allNamesObject,
    selectedProcedures,
    selectedHealthcareServices,
    selectedOperatorNames,
    selectedPractitionerTypes,
    hasJustIncompleteSurgeries
}) =>
    surgeryAssignmentList.filter((surgery) => {
        const {procedureCategory, procedureName, healthcareServiceId, assignedPractitioners} = surgery;
        const allIds = assignedPractitioners.map(({practitioners}) => practitioners).flat(2);
        const idsNoDuplicates = [...new Set(allIds)];
        const operatorNameList = getNames(idsNoDuplicates, allNamesObject);
        const practitionerTypeList = uniq(assignedPractitioners.filter((el) => el.practitioners.length > 0).map((el) => el.type));

        const includedCategories = filterCategories(procedureCategory, selectedCategories);
        const includedSurgeryNames = filterProcedureNames(procedureName, selectedProcedures);
        const includedHealthcareService = filterHealthCareServiceId(healthcareServiceId, selectedHealthcareServices);
        const includedOperatorNames = filterOperatorNames(operatorNameList, selectedOperatorNames);
        const includedPractitionerTypes = filterPractitionerTypes(practitionerTypeList, selectedPractitionerTypes);

        const surgeryWithWarnings = hasNoPractitioners(assignedPractitioners);

        if (hasJustIncompleteSurgeries)
            return (
                surgeryWithWarnings &&
                includedCategories &&
                includedSurgeryNames &&
                includedHealthcareService &&
                includedOperatorNames &&
                includedPractitionerTypes
            );

        return (
            includedCategories && includedSurgeryNames && includedHealthcareService && includedOperatorNames && includedPractitionerTypes
        );
    });
