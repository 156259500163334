import {makeStyles} from "tss-react/mui";
/**
 * @todo #14788: theme.typography throws error, to be fixed
 * @type {object}
 */
export default makeStyles()((theme) => ({
    dialogWrapper: {
        maxWidth: "780px",
        background: "#fff",
        borderRadius: "10px"
    },
    title: {
        padding: "2.5rem 2.5rem 0",
        textTransform: "initial",
        display: "flex",
        justifyContent: "space-between",
        ...theme.typography.h6
    },
    icon: {
        color: theme.palette.grey[700],
        cursor: "pointer",
        fontSize: "1.4rem",
        marginTop: theme.spacing("xxs")
    },
    name: {
        paddingBottom: theme.spacing("l")
    },
    errorArea: {
        minHeight: "3rem",
        color: theme.palette.error.main,
        fontSize: "0.866rem",
        padding: "0.5rem 0"
    },
    tableWrapper: {
        "overflow": "auto",
        "marginBottom": theme.spacing("l"),
        "& .MuiTableCell-stickyHeader": {
            backgroundColor: "#fff"
        },
        "& .MuiTableSortLabel-root": {
            width: "100%"
        }
    },
    tableWrapperSelectorOpen: {
        "& .MuiTableCell-stickyHeader": {
            backgroundColor: theme.palette.grey[100]
        }
    },
    categoriesLabel: {
        width: "100%",
        textAlign: "center"
    },
    categoryLabel: {
        paddingLeft: "4px"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "space-between !important"
    },
    button: {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing("xs")} 1rem`,
        letterSpacing: "0.3px"
    },
    buttonRemove: {
        fontSize: "14px",
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "capitalize",
        padding: "2px"
    },
    checkboxesWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around"
    },
    menu: {
        "borderBottom": `1px solid ${theme.palette.grey[200]}`,
        "height": "50px",
        "position": "relative",
        "display": "flex",
        "alignItems": "center",
        "width": "100%",
        "lineHeight": "50px",
        "padding": "0 1rem",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
    },
    checkbox: {
        padding: "2px 9px"
    },
    checkboxError: {
        "&:hover": {
            backgroundColor: "rgba(235, 202, 202, 0.8) !important"
        }
    },
    renderValue: {
        color: theme.palette.primary.main
    },
    menuWrapper: {
        "width": "685px",
        "marginTop": theme.spacing("s"),
        "marginLeft": "-0.5rem",
        "display": "flex",
        "flexDirection": "column",
        "& ul": {
            padding: 0
        },
        "& .MuiMenuItem-root.Mui-selected": {
            color: "unset"
        }
    },
    menuRows: {
        "height": "295px",
        "overflow": "auto",
        "display": "flex",
        "flexDirection": "column",
        "&:hover": {
            backgroundColor: "unset"
        }
    },

    selectorCheckbox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    selectorButtonWrapper: {
        "margin": theme.spacing("m"),
        "display": "flex",
        "justifyContent": "space-between !important",
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    isSurgeonSelectorOpen: {
        background: theme.palette.grey[100]
    },
    select: {
        "&:focus": {
            backgroundColor: "unset"
        }
    },
    surgeonName: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));
