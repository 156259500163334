// @ts-check
import {Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {
    hasErrorOnSaveAction,
    loadSurgeonOptionsList,
    loadSurgeryAssignmentList
} from "../../pages/surgery_assignment/surgery_assignment_actions";
import {
    selectSurgeryAssignmentList,
    selectSurgeryAssignmentWarningCount
} from "../../pages/surgery_assignment/surgery_assignment_selectors";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {selectAllStandardNamesObject} from "../private_data/private_data_selectors";
import DetailDialog from "../shared/detail_dialog/detail_dialog";
import SurgeryAssignmentManageDialog from "../surgery_assignment_manage_dialog/surgery_assignment_manage_dialog";
import SurgeryAssignmentPractitionerDialog from "../surgery_assignment_practitioner_dialog/surgery_assignment_practitioner_dialog";
import {FiltersForm} from "./components/filters_form";
import {SurgeriesTable} from "./components/surgeries_table";
import {SurgeriesWarning} from "./components/surgeries_warning";
import {filterSurgeries} from "./helpers";
import useStyles from "./surgery_assignment_canvas.styles";

/**
 * Renders the whole canvas inside the SurgeryAssignmentPage component
 *
 * @return {React.ReactElement}
 */
export const SurgeryAssignmentCanvas = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const surgeryAssignmentList = useSelector(selectSurgeryAssignmentList);
    const warningCount = useSelector(selectSurgeryAssignmentWarningCount);

    /** @type {IdToNameCollection|{}} */
    const allNamesObject = useSelector(selectAllStandardNamesObject({type: "practitioner"}));

    // state
    const [page, setPage] = useState(0);
    const [hasJustIncompleteSurgeries, setHasJustIncompleteSurgeries] = useState(false);
    const [procedure, setProcedure] = useState(null); // info for the SurgeryAssignmentManageDialog, if it's set, the dialog will be opened.
    const [practitioner, setPractitioner] = useState(null); // info for the SurgeryAssignmentPractitionerDialog, if it's set, the dialog will be opened.

    // form
    const defaultValues = {
        surgeryNameFilter: [],
        operatorNameFilter: [],
        procedureCategory: [],
        healthcareServiceId: [],
        practitionerTypeFilter: []
    };

    const {control, reset, getValues, watch} = useForm({defaultValues});
    const selectedCategories = watch("procedureCategory");
    const selectedHealthcareServices = watch("healthcareServiceId");
    const selectedProcedures = watch("surgeryNameFilter");
    const selectedOperatorNames = watch("operatorNameFilter");
    const selectedPractitionerTypes = watch("practitionerTypeFilter");

    // effects
    useEffect(() => {
        if (organizationId) {
            // fetch practitioner options for entire hcservices (all possible surgeons)
            dispatch(loadSurgeonOptionsList());
            dispatch(loadSurgeryAssignmentList());
        }
    }, [organizationId]);

    useEffect(() => {
        if (!warningCount && hasJustIncompleteSurgeries) {
            setHasJustIncompleteSurgeries(false);
        }
    }, [warningCount]);

    // handlers
    const handleResetAll = () => {
        reset();
        setHasJustIncompleteSurgeries(false);
    };
    const handleOpenManageDialog = (procedure) => {
        setProcedure(procedure);
    };
    const handleCloseManageDialog = () => {
        setProcedure(null);
        dispatch(hasErrorOnSaveAction([])); // reset patch errors when closing the SurgeryAssignmentManageDialog
    };
    const toggleShowWarnings = () => setHasJustIncompleteSurgeries((prevState) => !prevState);
    const handleClosePractitionerDialog = () => setPractitioner(null);
    const handleOpenPractitionerDialog = ({practitionerId, procedureCode}) => setPractitioner({practitionerId, procedureCode});
    const handleOpenManageDialogFromPractitionerDialog = (procedureCode) => {
        const procedure = surgeryAssignmentList.find((surgeryAssignment) => surgeryAssignment.procedureCode === procedureCode);
        setProcedure(procedure);
    };

    // filter
    const surgeriesFiltered = filterSurgeries({
        surgeryAssignmentList,
        selectedCategories,
        allNamesObject,
        selectedProcedures,
        selectedHealthcareServices,
        selectedOperatorNames,
        selectedPractitionerTypes,
        hasJustIncompleteSurgeries
    });

    return (
        <>
            {Boolean(warningCount) && (
                <SurgeriesWarning
                    hasWarning={hasJustIncompleteSurgeries}
                    toggleShowWarnings={toggleShowWarnings}
                    warningCount={warningCount}
                />
            )}
            <div aria-level={1} className={classes.title} role="heading">
                {t("SurgeryAssignmentPage.title")}
            </div>
            <div className={classes.content}>
                <div className={classes.filtersWrapper}>
                    <FiltersForm control={control} getValues={getValues} reset={reset} />
                    <div className={classes.resetButtonWrapper}>
                        <Button className={classes.resetButton} component="span" onClick={handleResetAll}>
                            {t("SurgeryAssignmentCanvas.resetAll")}
                        </Button>
                    </div>
                </div>
                <div className={classes.tableWrapper}>
                    <SurgeriesTable
                        allNamesObject={allNamesObject}
                        page={page}
                        surgeries={surgeriesFiltered}
                        onOpenManageDialog={handleOpenManageDialog}
                        onOpenPractitionerDialog={handleOpenPractitionerDialog}
                        onSetPage={setPage}
                    />
                </div>
                {procedure && (
                    <DetailDialog open={Boolean(procedure)} onClose={handleCloseManageDialog}>
                        <SurgeryAssignmentManageDialog procedure={procedure} onClose={handleCloseManageDialog} />
                    </DetailDialog>
                )}
                {practitioner && (
                    <DetailDialog open={Boolean(practitioner)} onClose={handleClosePractitionerDialog}>
                        <SurgeryAssignmentPractitionerDialog
                            practitioner={practitioner}
                            onClose={handleClosePractitionerDialog}
                            onOpenManageDialog={handleOpenManageDialogFromPractitionerDialog}
                        />
                    </DetailDialog>
                )}
            </div>
        </>
    );
};
