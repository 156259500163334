import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import {useSelector} from "react-redux";

import {selectCurrentUser} from "../../redux/app_selectors";

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function SupportPopover() {
    const user = useSelector(selectCurrentUser);
    const handleClick = (event) => {
        // Get user and system information
        const systeminformation = `ViewPort: ${window.innerWidth} x ${window.innerHeight}
Display: ${window.screen.width} x ${window.screen.height}`;
        // Set user mail
        let email = user.email;
        if (email.includes("@dhmdom.intern.dhm.mhn.de")) {
            email = email.replace(/dhmdom.intern./g, "");
        }
        // eslint-disable-next-line new-cap, no-undef
        FreshworksWidget("hide", "ticketForm", [
            "name",
            "email",
            "custom_fields.cf_systeminformationen",
            "custom_fields.cf_url",
            "custom_fields.cf_rolle"
        ]);
        // eslint-disable-next-line new-cap, no-undef
        FreshworksWidget("identify", "ticketForm", {
            name: `${user.firstName} ${user.lastName}`,
            email: email,
            custom_fields: {
                cf_systeminformationen: systeminformation,
                cf_url: window.location.pathname,
                cf_rolle: `${user.roles}`
            }
        });
        // eslint-disable-next-line new-cap, no-undef
        FreshworksWidget("open");
    };

    return (
        <>
            <Tooltip aria-label="Support-Formular" title="Support-Formular">
                <Fab aria-label="add" className="support-fab" color="primary" size="small" onClick={handleClick}>
                    <ContactSupportIcon />
                </Fab>
            </Tooltip>
        </>
    );
}
