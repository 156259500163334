// @ts-check
import {Button} from "@mui/material";
import {t} from "i18next";
import React from "react";

import {sortWithLocale} from "../../../utils/sort_with_locale";
import PermissionTooltip from "../../shared/permission_tooltip";

export const COLUMN_WIDTH = {name: 30, type: 25, count: 25, actions: 20};

/**
 * Depending on the flag 'areAllExperiencesVisible' shows ALL experiences or just ONE experience
 *
 * @param {object} params
 * @param {boolean} params.areAllExperiencesVisible
 * @param {PractitionerExperienceExtended} params.practitionerExperience
 * @return {PractitionerExperienceExtended}
 */
export const filterExperiencesAmount = ({areAllExperiencesVisible, practitionerExperience}) =>
    areAllExperiencesVisible
        ? practitionerExperience
        : {practitionerId: practitionerExperience?.practitionerId, experience: [practitionerExperience?.experience[0]]};

/**
 * Translates and sorts the experience type, for instance surgeon1, mentor1, etc.
 *
 * @param {Array<string>} typeList The list of category types for the current surgery
 * @return {string} The translated, sorted and parsed types.
 */
const translateType = (typeList) =>
    typeList
        .sort(sortWithLocale)
        .map((el) => t(`SurgeryAssignmentManageDialog.${el}`))
        .join(", ");

/**
 * Formats the data to be passed to the Datatable component
 *
 * @param {Object} params
 * @param {PractitionerExperienceExtended} params.tableData
 * @param {Function} params.onOpenManageDialog
 * @param {String} params.className
 * @param {boolean} params.isModifyAllowed
 * @return {Array<{count: number, display: object, id: string, procedureName: string, tooltip: {procedureName: string}, type: string}>}
 */
export const formatTableData = ({tableData, onOpenManageDialog, className, isModifyAllowed}) => {
    const datableDataFormatted = [];
    tableData.experience.forEach((row) => {
        const {procedureCode: id, procedureName, type, count, typeList} = row;
        const typeTranslated = translateType(typeList);

        datableDataFormatted.push({
            id: id + type,
            key: id + type,
            procedureName,
            type,
            count,
            display: {
                procedureName,
                type: typeTranslated,
                count,
                action: (
                    <PermissionTooltip isAllowed={isModifyAllowed}>
                        <Button className={className} disabled={!isModifyAllowed} onClick={() => onOpenManageDialog(id)}>
                            {t("SurgeryAssignmentPractitionerDialog.surgeryView")}
                        </Button>
                    </PermissionTooltip>
                )
            },
            tooltip: {
                procedureName
            }
        });
    });
    return datableDataFormatted;
};

/**
 * Prepare the labels for the Datatable component
 *
 * @return{Array<DataTableLabel>}
 */
export const getLabels = () => [
    {
        id: "procedureName",
        label: t("SurgeryAssignmentPractitionerDialog.procedureName"),
        width: `${COLUMN_WIDTH.name}%`,
        setTitle: true
    },
    {
        id: "type",
        label: t("SurgeryAssignmentPractitionerDialog.type"),
        width: `${COLUMN_WIDTH.type}%`,
        setTitle: false
    },
    {
        id: "count",
        label: t("SurgeryAssignmentPractitionerDialog.count"),
        width: `${COLUMN_WIDTH.type}%`,
        setTitle: false
    },
    {
        id: "action",
        label: "",
        width: `${COLUMN_WIDTH.actions}%`,
        setTitle: false
    }
];
