// @ts-check
import uniq from "lodash/uniq";
import {func, object} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {
    selectSurgeonIdOptionsList,
    selectSurgeryAssignmentList,
    selectSurgeryNameList
} from "../../../pages/surgery_assignment/surgery_assignment_selectors";
import {sortArrayOfObjectsByKey} from "../../../utils/sort_array_of_objects_by_key";
import {selectSurgeryAssignmentConfig} from "../../fe_settings/fe_settings_selectors";
import {selectAllStandardNamesObject} from "../../private_data/private_data_selectors";
import {ControlledSelectorMultiple} from "../../shared/controlled_form";
import {ControlledAutocompleteMultiple} from "../../shared/controlled_form/controlled_autocomplete_multiple/controlled_autocomplete_multiple";
import {getNames} from "../helpers";
import useStyles from "../surgery_assignment_canvas.styles";

/** @typedef {import('react-hook-form').UseFormGetValues<any>} UseFormGetValues */

/**
 * A form containing all the filters for the surgeries table
 *
 * @param {object} props The props
 * @param {object} props.control The RHK control object
 * @param {Function} props.reset A RHF handler to reset all values
 * @param {UseFormGetValues} props.getValues A RHF handler to get the current form values
 * @return {React.ReactElement}
 */
export const FiltersForm = ({control, reset, getValues}) => {
    const {t} = useTranslation();
    const {classes} = useStyles();
    // redux
    const {groupProcedureBy, categories} = useSelector(selectSurgeryAssignmentConfig);
    const surgeryAssignmentList = useSelector(selectSurgeryAssignmentList);
    const surgeryNameList = useSelector(selectSurgeryNameList);
    const practitionerIdList = useSelector(selectSurgeonIdOptionsList);

    /** @type {IdToNameCollection|{}} */
    const allNamesObject = useSelector(selectAllStandardNamesObject({type: "practitioner"}));
    const operatorNameList = [...new Set(getNames(practitionerIdList, allNamesObject))]; // get the unique names

    const surgeryNameListSorted = surgeryNameList
        .map((el) => ({label: el, value: el}))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    const operatorNameListSorted = operatorNameList
        .map((el) => ({value: el, label: el}))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    const hasMoreThanOneCategory = categories.length > 1;
    const categoriesFormatted = categories.map((el) => ({value: el, label: t(`SurgeryAssignment.${el}`, el)}));

    return (
        <form>
            {hasMoreThanOneCategory && (
                <ControlledSelectorMultiple
                    classesStyle={classes.select}
                    control={control}
                    getValues={getValues}
                    items={categoriesFormatted}
                    name="practitionerTypeFilter"
                    placeholder={t("App.pleaseSelect")}
                    reset={reset}
                    title={t("SurgeryAssignmentCanvas.executionRights")}
                />
            )}
            <ControlledAutocompleteMultiple
                classesStyle={classes.autocomplete}
                control={control}
                hasTooltip
                items={surgeryNameListSorted}
                name="surgeryNameFilter"
                placeholder={t("App.pleaseSelect")}
                title={t("SurgeryAssignmentCanvas.surgery")}
            />

            <ControlledAutocompleteMultiple
                classesStyle={classes.autocomplete}
                control={control}
                items={operatorNameListSorted}
                name="operatorNameFilter"
                placeholder={t("App.pleaseSelect")}
                title={t("SurgeryAssignmentCanvas.operator")}
            />
            {groupProcedureBy.map((groupName) => {
                const procedureItems = [];
                surgeryAssignmentList.forEach((surgeryAssignment) => {
                    if (surgeryAssignment[groupName] !== undefined) {
                        procedureItems.push(surgeryAssignment[groupName]);
                    }
                });
                const procedureItemsUnique = uniq(procedureItems);
                /** @type {Array<{label: string|object, value: string}>} */
                const procedureItemsSorted = procedureItemsUnique
                    .map((el) => ({value: el, label: t(`HealthcareService.${el}`, el)}))
                    .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));
                return (
                    <ControlledSelectorMultiple
                        classesStyle={classes.select}
                        control={control}
                        dataTestid={`controlled-selector-multiple-${groupName}`}
                        getValues={getValues}
                        items={procedureItemsSorted}
                        key={`${groupName}`}
                        name={`${groupName}`}
                        placeholder={t("App.pleaseSelect")}
                        reset={reset}
                        title={t(`SurgeryAssignmentCanvas.${groupName}`)}
                    />
                );
            })}
        </form>
    );
};
FiltersForm.propTypes = {
    control: object.isRequired,
    reset: func.isRequired,
    getValues: func.isRequired
};
