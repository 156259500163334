// @ts-check
import {Button} from "@mui/material";
import {array, func, number, object} from "prop-types";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {PERMISSION, useSecurity} from "../../../utils/security";
import {selectSurgeryAssignmentConfig} from "../../fe_settings/fe_settings_selectors";
import DataTable from "../../shared/data_table/data_table";
import PermissionTooltip from "../../shared/permission_tooltip";
import {
    addChecksToRow,
    addGroupsToRow,
    buildLabelGroups,
    buildLabelTypes,
    formatNameColumnsStructure,
    getBasicLabels,
    getEditLabel,
    getPractitionersNamesAndIds,
    hasNoPractitioners,
    mapNamesToSpans
} from "../helpers";
import useStyles from "../surgery_assignment_canvas.styles";

const sortableColumns = ["group-healthcareServiceId"];

/**
 * Renders the surgeries' table on the Surgery Assignment Canvas
 *
 * @param {object} props The props
 * @param {Array<ProcedureInfo>} props.surgeries The list ProcedureInfo items that comes from our api call to URL_SURGERY_ASSIGNMENT
 * @param {number} props.page The zero-based index of the current page
 * @param {Function} props.onSetPage A handler to change the page number of the table
 * @param {Function} props.onOpenManageDialog A handler to open the manage dialog
 * @param {IdToNameCollection} [props.allNamesObject] The object containing the ids mapped to real names
 * @param {Function} props.onOpenPractitionerDialog A handler to open the practitioner dialog
 * @return {React.ReactElement}
 */
export const SurgeriesTable = ({surgeries, page, onSetPage, onOpenManageDialog, allNamesObject, onOpenPractitionerDialog}) => {
    const {t} = useTranslation();
    const {classes} = useStyles();
    const {isGranted} = useSecurity();
    const isModifyAllowed = isGranted(PERMISSION.MODIFY_SURGERYASSIGNMENT);

    /**
     * @description Set the table page back to 0 whenever a filter is applied
     */
    useEffect(() => {
        onSetPage(0);
    }, [surgeries.length]);

    // redux store
    const {columns, groupProcedureBy, nameDecorator} = useSelector(selectSurgeryAssignmentConfig);

    const basicLabels = getBasicLabels();
    const groupLabels = buildLabelGroups(groupProcedureBy);
    const checkLabels = buildLabelTypes({columns, type: "check"});
    const nameLabels = buildLabelTypes({columns, type: "names"});
    const editLabel = getEditLabel();
    const labelsExtended = [...basicLabels, ...groupLabels, ...checkLabels, ...nameLabels, ...editLabel];
    const labelsNoSort = [
        ...groupLabels.filter((label) => !sortableColumns.includes(label.id)).map(({id}) => id),
        ...checkLabels.map(({id}) => id),
        ...nameLabels.map(({id}) => id)
    ];

    const surgeriesFormatted = surgeries.map((procedureInfo) => {
        const {procedureName, assignedPractitioners, procedureCode} = procedureInfo;

        const checkColumnList = addChecksToRow(columns, assignedPractitioners);
        const groupsColumn = addGroupsToRow(groupProcedureBy, procedureInfo);

        const practitionerNameAndIdList = getPractitionersNamesAndIds(assignedPractitioners, allNamesObject);
        const namesColumnList = formatNameColumnsStructure({
            columns,
            practitionerNameAndIdList,
            nameDecorator
        });
        const {namesColumnsSpanList} = mapNamesToSpans(namesColumnList, onOpenPractitionerDialog, procedureCode);

        return {
            id: procedureCode,
            key: procedureCode,
            surgery: procedureName,
            ...groupsColumn,
            display: {
                surgery: procedureName,
                ...groupsColumn,
                ...checkColumnList,
                ...namesColumnsSpanList,
                edit: (
                    <PermissionTooltip isAllowed={isModifyAllowed}>
                        <Button
                            className={classes.buttonEdit}
                            disabled={!isModifyAllowed}
                            size="small"
                            onClick={() => onOpenManageDialog(procedureInfo)}
                        >
                            {t("App.edit")}
                        </Button>
                    </PermissionTooltip>
                )
            },
            tooltip: {surgery: procedureName, ...groupsColumn},
            isEmergency: hasNoPractitioners(assignedPractitioners)
        };
    });

    return (
        <div data-testid="surgery-assignments-table">
            <DataTable
                data={surgeriesFormatted}
                defaultSorted="surgery"
                disableSortColumns={["edit", ...labelsNoSort]}
                hasStickyPagination
                labels={labelsExtended}
                page={page}
                tableLeftRightPadding={190} // 64 (left sidebar) + 60 + 32 + 15 = 156
                tableTopBottomPadding={500}
                onSetPage={onSetPage}
            />
        </div>
    );
};

SurgeriesTable.propTypes = {
    surgeries: array.isRequired,
    page: number.isRequired,
    onSetPage: func.isRequired,
    onOpenManageDialog: func.isRequired,
    allNamesObject: object,
    onOpenPractitionerDialog: func.isRequired
};
