// @ts-check
import {Button} from "@mui/material";
import {func, object} from "prop-types";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {PERMISSION, useSecurity} from "../../../utils/security";
import DataTable from "../../shared/data_table/data_table";
import useStyles from "../surgery_assignment_practitioner_dialog.styles";
import {filterExperiencesAmount, formatTableData, getLabels} from "./helpers";

export const TABLE_WIDTH = 680;

const TABLE_HEIGHT_WITH_SURGERIES = 430; // 740 - 240 - 70 (max height of the dialog: 800px - the height of rest items: 240px - the height of the hideSurgeries Button: 70px)
const TABLE_HEIGHT_ONE_SURGERY = 100;
export const COLUMN_WIDTH = {name: 30, type: 25, count: 25, actions: 20};

/**
 * Render SurgeryAssignmentPractitionerTable component
 *
 * @param {Object} props
 * @param {PractitionerExperienceExtended} props.practitionerExperience
 * @param {Function} props.onOpenManageDialog
 * @return {React.ReactElement}
 */
const SurgeryAssignmentPractitionerTable = ({practitionerExperience, onOpenManageDialog}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {isGranted} = useSecurity();
    const isModifyAllowed = isGranted(PERMISSION.MODIFY_SURGERYASSIGNMENT);

    const [areAllExperiencesVisible, setAreAllExperiencesVisible] = useState(false);
    const hasOneExperience = practitionerExperience.experience.length === 1;

    const handleVisibleExperiences = () => setAreAllExperiencesVisible((prevState) => !prevState);

    // format data
    const experiencesFiltered = filterExperiencesAmount({areAllExperiencesVisible, practitionerExperience});
    const tableDataFormatted = formatTableData({
        tableData: experiencesFiltered,
        onOpenManageDialog,
        className: classes.buttonOpView,
        isModifyAllowed
    });

    const labels = getLabels();
    const tableHeight = areAllExperiencesVisible ? TABLE_HEIGHT_WITH_SURGERIES : TABLE_HEIGHT_ONE_SURGERY;

    return (
        <div className={classes.practitionerDialogSize}>
            <div
                className={classes.tableWrapper}
                data-testid="surgery-assignments-table"
                style={{height: tableHeight + "px", overflowX: "hidden"}}
            >
                <DataTable
                    data={tableDataFormatted}
                    disableSortColumns={["type", "action"]}
                    isPaginationVisible={false}
                    labels={labels}
                    tableTopBottomPadding={0}
                    tableWidthProps={TABLE_WIDTH}
                />
            </div>
            {!hasOneExperience && (
                <div className={classes.buttonShowHideWrapper}>
                    <Button className={cx(classes.buttonOpView, classes.buttonShowHide)} onClick={handleVisibleExperiences}>
                        {areAllExperiencesVisible
                            ? t("SurgeryAssignmentPractitionerDialog.hideSurgeries")
                            : t("SurgeryAssignmentPractitionerDialog.showAllSurgeries")}
                    </Button>
                </div>
            )}
        </div>
    );
};

SurgeryAssignmentPractitionerTable.propTypes = {
    practitionerExperience: object.isRequired,
    onOpenManageDialog: func.isRequired
};

export default SurgeryAssignmentPractitionerTable;
