import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    list: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },
    item: {
        padding: `${theme.spacing("xs")} 0`,
        fontSize: "1rem",
        fontWeight: 300
    },
    mainItem: {
        padding: `${theme.spacing("m")} 0 ${theme.spacing("s")} 0`,
        fontSize: "1rem",
        fontWeight: 500
    },
    link: {
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        textDecoration: "none"
    }
}));
